
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');



body {
  line-height: 1;
  font-family: "Inter", sans-serif !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* width */
body::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd !important;
  border-radius: 10px !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #ccc !important;
  border-radius: 10px !important;
}

.relative {
  position: relative;
}

.bg-gray {
  background-color: #f9f9f8;
}

.bg-color {
  background-color: #0b2230;
}

.content-wrapper h2,
.heading-wrapper h2 {
  text-transform: capitalize;
}

.txt-white {
  color: #fff;
}

.bg-yellow {
  background-color: #ffe603;
}

body {
  line-height: 1;
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  color: #16447d;
  font-family: "Inter", sans-serif;
}

h2 {
  font-size: 24px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  color: #0b2230 !important;
  line-height: 40px !important;
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #000;
}

.custom-btn {
  padding: 20px;
  background-color: #0b2230;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  outline: none;
  border-radius: 5px;
  transition: 0.5s ease-in-out;
}
.custom-btn:focus {
  outline: none;
}
.custom-btn.add-prop {
  padding: 12px 30px;
  transition: 0.5s ease-in-out;
}
.custom-btn.add-prop svg {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.5s ease-in-out;
  padding:10px 0px;
}
header.fixed {
  background-color: #0b2230;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding:10px 0px;
}
header.fixed nav ul li a {
  padding: 20px 10px;
}
header.fixed .custom-btn.add-prop {
  background-color: #ffe603;
  color: #0b2230;
}
.logo{
  padding-left:30px;
}
.logo a img {
  width: 70px;
  margin-right: 30px;
  filter: brightness(0) invert(1);
}

nav ul li {
  position: relative;
}
nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 20px 13px;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  transition: 0.5s ease-in-out;
}
nav > ul > li:not(:last-of-type){
  margin-right:30px;
}
nav ul li .dropdownMenu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 200px;
  background-color: #fff;
  padding: 10px 0px;
  box-shadow: 0 1px 5px rgba(70, 70, 70, 0.2);
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: 0.3s ease-in-out;
  max-height:200px;
  overflow-y: auto;
}
nav ul li .dropdownMenu ul li {
  margin: 0px;
}
nav ul li .dropdownMenu ul li a {
  color: #000;
  padding: 10px 20px;
  display: block;
  transition: 0.3s ease-in-out;
}
nav ul li .dropdownMenu ul li a:hover {
  color: #0b2230;
}
nav ul li .dropdownMenu ul li:not(:last-of-type) a {
  border-bottom: 1px solid #f1f1f1;
}
nav ul li:hover .dropdownMenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.social-icon li a{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0b2230;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.social-icon li:not(:last-of-type){
  margin-right:10px;
}
.social-icon li a svg{
  fill: #fff;
  font-size: 16px;
}
.footer-social-icon ul li{
  margin-right:20px;
}
.footer-social-icon ul li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border:1px solid #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  transition: 0.5s ease-in-out;
}
.footer-social-icon ul li a svg {
  fill: #fff;
  font-size: 20px;
}
footer .footer-social-icon ul li a:hover{
  background-color: #ffe603;
  border-color:#ffe603;
}
footer .footer-social-icon ul li a:hover svg{
  fill:#000;
}

.call-now {
  text-decoration: none;
  color: #0b2230;
  font-size: 18px;
  font-weight: 500;
}

.right-header ul li a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.right-header ul li a.signin {
  display: inline-block;
  margin-right: 20px;
}
.right-header ul li a.signin svg {
  font-weight: 700;
  font-size: 20px;
}

.contect-area {
  background-color: #ffe603;
}
.contect-area h3 {
  color: #16447d;
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.2em;
}
.contect-area .custom-btn {
  border-radius: 100px;
}

.footer-left h6 {
  color: #fff;
  font-size: 24px;
  margin-top: 20px;
}
.footer-left a img{
  width:84px;
  filter: brightness(0) invert(1);
}

.footer-menu h6 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 25px;
  font-weight:600;
}

.footer-menu ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 20px;
  margin: 16px 0px;
  display: block;
  text-decoration: none;
}
.contact-me a{
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  margin: 16px 0px;
  display: block;
  text-decoration: none;
}

.email-detail p {
  color: #fff;
  margin-top: 30px;
}

.email-detail .form-area {
  width: 90%;
  position:relative;
  
}
.email-detail .form-area input {
  width: 100%;
  padding: 12px 70px 12px 12px;
  border: none;
  background-color: #fff;
}
.email-detail .form-area input:focus {
  outline: none;
}
.email-detail .form-area button {
  padding: 12px 15px;;
  border: none;
  /* background-color: #ffe603; */
  position:absolute;
  right:0;
  top:0;
  
}
.email-detail .form-area button  svg{
  font-weight: 600;
  color: #0b2230;
  font-size: 20px;
}

.banner{
  /* background-image: url("../images/banner.jpg"); */
 
  position: relative;
}
.banner_bg{
  background-size: cover;
  padding-top: 110px;
  height: 600px;
  background-position: center center;
}

/* .banner_bg:before{
  content: "";
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.2);
} */
/* .banner-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
} */
.bg-property{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  margin-top:40px;
}
.banner .banner-content h1{
  font-size:40px;
  line-height:48px;
}
.banner .banner-content p,
.banner .banner-content h1 {
  color: #fff;
  font-family: "Inter", sans-serif;
}
.banner .banner-content p {
  font-size:20px;
  font-weight:500;
  line-height:24px;
}
.banner.inner_banner{
  height:auto;
  background-image:none;
  height:400px;
  padding:0px;
  position:relative;
}
.banner.inner_banner .banner-content{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
}
.inner-banner-image{
  width:100%;
  height:100%;
  object-fit:cover;
}
.inner-banner-image img{
  width:100%;
  height:100%;
}

.searchengen {
  position: relative;
  background-color: #fff;
  border-radius:50px;
  padding:12px 15px 10px;
}
.sidebar .select-drop{
  display:none;
}
.select-drop{
  position:absolute;
  left:0;
  top:100%;
  width:100%;
  background-color:#fff;
  margin-top: 8px;
  z-index:9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.search_drop{
  position:relative;
}
.search_drop.active .select-drop{
  display:block;
}
.select-drop ul li{
  padding:10px 10px;
  cursor:pointer;
 border-bottom:1px solid #ddd;
 transition:0.5s ease-in-out;
}
/* .select-drop ul li:hover{
  background-color:#0b2230;
  color:#fff;
} */

.search-box {
  width: 27%;
  background-color: #fff;
  text-align: left;
}
.search-box{
  padding-right:20px;
}
.searchengen .search-box:not(:last-of-type){
  border-right:1px solid #ddd;
}

.search-box:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.search-box:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.search-box:last-of-type .form-item {
  width: 60%;
}
.search_drop input, .search_drop select{
  width:100%;
  border:none;
  background-color:transparent;
  text-align:left;
  padding:10px 24px 10px 10px;
  color:#000;
  font-size:16px;
  font-weight:400;
}
.search_drop input:focus, .search_drop select:focus{
  outline:none;
}
.search_drop input::placeholder {
  color: #0B2230;
  opacity: 1; /* Firefox */
  font-size:20px;
  font-weight:400;
}
.search_drop select{
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.search_drop input::-ms-input-placeholder { /* Edge 12 -18 */
  color: #0B2230;
  opacity: 1; /* Firefox */
  font-size:20px;
  font-weight:400;
}
.search_drop{
  position:relative;
}
.search_drop svg{
  position:absolute;
  right:5px;
  top:15px;
  cursor:pointer;
}


.rang-slider {
  padding: 20px 10px 20px;
}

.price-range .rc-slider {
  margin-top: 5px;
}

.price-range .rc-slider-track,
.price-range .rc-slider-tracks {
  background-color: #0b2230;
}

.price-range .rc-slider-handle {
  border-color: #0b2230;
  background-color: #0b2230;
}
.price-range .rc-slider-handle:focus {
  box-shadow: none;
}

.price-range .pricevalue span {
  font-weight: 600;
}

.form-item {
  width: 100%;
  /* background-color: #fff; */
}
.form-item label {
  display: block;
  margin-bottom: 0px;
  color: #0B2230;
  font-size: 16px;
  font-weight: 400;
  padding:0px 10px;
}
.sidebar .submit-btn button{
  position:static;
  width:80px;
  transform:inherit;
  border-radius:10px;
}
.clearfitler{
  border:none;
  outline:none;
  border-radius:5px;
  padding:5px 10px;
  display:inline-block;
}
.sidebar .submit-btn button svg{
  font-size:30px;
}
.submit-btn button {
  background-color: #0b2230;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 42px;
  border-radius: 5px;
  border: none;
  border-radius: 30px;
  color:#fff;
  font-size:16px;
  font-weight:600;
}
.submit-btn button:focus {
  outline: none;
}
.submit-btn button svg {
  fill: #fff;
  font-size: 20px;
  margin-right:5px;
  vertical-align: middle;
}

.awards-inn {
  width: 100%;
  margin: -52px auto 0px;
  position: relative;
  background-color: #fff;
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 20px 10px;
}

.award-box {
  background-color: #fff;
  padding: 10px;
}
.award-box .pic {
  width: 75px;
  margin-right: 30px;
}
.award-box .pic img {
  width: 100%;
}
.award-box .award-detail h2 {
  font-size: 40px;
  color: #0b2230;
}
.product-inner-list-card .card-image{
  width:378px;
  height:306px;
}
.popular-location .card-image{
  height:276px;
}
.card-image {
  width:100%;
  height:256px;
  position:relative;
  border-radius:20px;
  overflow:hidden;
}
.card-image img{
  width:100%;
  height:100%;
  object-fit:cover;
  border-radius:20px;
}
.card-image .card-title{
  padding:8px 10px;
  text-align:center;
  font-size:16px;
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  display:block;
  background-color:rgba(0, 0, 0, 0.5);
  color:#fff;
  font-weight:500;
}
.list-card-content{
  width:calc(100% - 378px);
  padding-right:0px;
  padding-left:30px;
}
.list-card-content h3 a{
  font-size:24px;
  font-weight:700;
  line-height:40px;
  color:#0B2230;
  text-decoration:none;
}
.list-card-content .location-title{
  font-size:16px;
  font-weight:400;
  line-height:24px;
  color:#0B2230;
}


.feature-card-slider {
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
}

.card-item {
  transition: 0.5s ease-in-out;
}
.card-item:hover {
  box-shadow: 0px 0px 30px 0px rgba(19, 19, 28, 0.12);
}
.card-item .feature-image {
  width: 100%;
  height: 250px;
  background-color: #f2f3f4;
}
.card-item .feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-item .feature-image .date-area {
  position: absolute;
  left: 40px;
  bottom: 20px;
  background-color: #fff;
  color: #0b2230;
  border-radius: 5px;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  height: 45px;
  padding-right: 20px;
}
.card-item .feature-image .date-area span {
  background: #ffe603;
  height: 100%;
  margin-right: 20px;
  padding: 0 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: #0b2230;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.card-item .feature-image.place-image {
  height: 350px;
}
.card-item .feature-content {
  padding: 20px 15px;
  position: relative;
  background-color: #fff;
}
.card-item .feature-content .badge {
  font-size: 14px;
  text-transform: capitalize !important;
  color: #fff;
  background-color: #0b2230;
  display: inline-block;
  padding: 5px;
}
.card-item .feature-content h4 {
  font-size: 18px;
  color: #16447d;
  font-weight: 700;
}
.card-item .feature-content ul li {
  display: flex;
  flex-direction: column;
}
.card-item .feature-content ul li span {
  margin-top: 10px;
  display: inline-block;
}
.card-item .feature-content ul li span svg {
  vertical-align: middle;
}
.card-item .feature-content ul li:not(:last-of-type) {
  margin-right: 15px;
}
.card-item .user-image {
  width: 47px;
  height: 47px;
  border: 3px solid #fff;
  border-radius: 50%;
  background-color: #7e7e7e;
  margin-right: 15px;
}
.card-item .userName {
  color: #16447d;
  font-size: 16px;
  line-height: 1.5;
  text-transform: capitalize;
}
.card-item .price {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.card-item .price.comments {
  font-weight: 400;
  font-size: 18px;
}
.card-item .price.comments svg {
  vertical-align: middle;
}

/* .swiper-slide {
height: 550px !important;
} */
.swiper-nav {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top:50%;
  width: 100%;
  z-index: 1;
  margin-top:-20px;
}
.swiper-nav button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #0b2230;
  background-color:transparent;
  outline:none;
  transition:0.5s ease-in-out;
}
.swiper-nav button:hover{
  background-color:#0b2230;
  border-color:#0b2230;
}
.swiper-nav button:hover svg{
  fill:#fff;
}
.swiper-nav button svg {
  fill: #0b2230;
  transition:0.5s ease-in-out;
  margin-top:-5px;
}
.swiper-nav button.arrow-right{
  left:-50px;
  position: absolute;
}
.detail .swiper-nav button.arrow-right{
  left:20px;
}
.swiper-nav button.arrow-left {
  position: absolute;
  right: -50px;
}
.testimonial-slider{
  border:1px solid #000;
  margin-top:30px;
  border-radius:10px;
}

.testimonial-slider {
  border: 1px solid #000;
  margin-top: 30px;
  border-radius: 10px;
  /* background-image: url('../images/light.png'); */
  background-repeat: no-repeat;
  background-position: right center;
  /* Optionally adjust the size */
  /* background-size: contain; */
  z-index: 999;
}



.testimonial-slider .swiper {
  padding: 0px 10px 20px !important;
  width:70%;
}
.testimonial-slider h2{
  padding:0px 40px;
  display:inline-block;
  background-color:#fff;
  position:relative;
  margin-top: -40px;
  margin-left: 30px;
  top: -10px;
  font-size:32px !important;
  font-weight:600 !important;
}
.light-image {
  position: absolute;
  right: -33px;
  top: -50px;
  z-index: 999;
}
.testcard {
  padding: 10px 25px 70px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.testcard p{
  font-size:16px;
  font-weight:400;
  color:#000;
  width:80%;
  line-height:20px;
}
.testcard .client-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #7e7e7e;
  position: absolute;
  left: 20px;
  bottom: 0;
  margin-bottom: -55px;
}
.testcard .client-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.testcard .about-client {
  position: absolute;
  right: 20px;
  bottom: 0;
  margin-bottom: -65px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.testcard .about-client h6 {
  color: #0b2230;
  font-size: 16px;
  font-weight: 600;
}

.user-area a {
  text-decoration: none;
  padding: 10px;
  text-align: center;
  background-color: #ffe603;
  color: #0b2230;
  border-radius: 100px;
  font-weight: 500;
}
.listing-banner{
  width:100%;
   height:400px; 
  position:relative;
   /* background-image: url("../images/banner.jpg");  */
}
.listing-banner .content-banner{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  width:100%;
  margin-top:50px;
}
.listing-banner h1{
  color:#fff;
}
.overlay:before{
  content:"";
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.5);
}
.sidebar form{
  display:flex;
  align-items:center;
}
.sidebar .search-box{
  width:190px;
}
.sidebar .search-box .form-item{
  width:100%;
}
.sidebar .search-box .form-item{
  border:1px solid #000;
  border-radius:30px;
  padding:0px 5px;
}
.sidebar .search_drop svg{
  top:50%;
  transform: translateY(-50%);
}
.filter-sidebar h2 button{
  background-color:transparent !important;
  padding:10px 15px;
  text-transform:uppercase;
  font-weight:700;
 
}
.filter-sidebar h2 button:focus{
  outline:none;
  border-bottom:1px solid #ddd;
  box-shadow:none;
}
.filter-sidebar .accordion-item .accordion-body ul li a{
  text-decoration:none;
  padding:15px 15px;
  display:block;
  font-size:18px;
  color:#000;
  transition:0.5s ease-in-out;
}
.filter-sidebar .accordion-item .accordion-body ul li a:hover{
  background-color:#ffe603;
}
.filter-sidebar .accordion-item .accordion-body ul li:not(:last-of-type) a{
  border-bottom:1px solid #ddd;
}
.filter-sidebar .accordion-item .accordion-body{
  padding:0px;
}
.mt-100{
  margin-top:50px;
}

.breadcrum ol li a{
  text-decoration:none;
  color:#000;
  font-size:14px;
  font-weight:400;
}
.detail-heading h3{
  font-weight:600;
  margin-top:20px;
}
.detail-slider{
  width:100%;
  height:550px;
  position:relative;
}
.detail-slider .swiper{
  width:100%;
  height:100%;
}
.detail-slider .slider-image img,.detail-slider .slider-image{
  width:100%;
  height:100%;
}
.detail-slider .swiper-nav{
  width:100%;
  transform:translate(-50% -50%);
  top:50%;
  padding:0px 20px;
  bottom:inherit;
}
.detail-slider .swiper-nav button.arrow-left{
  right:20px;
}
.detail-slider .swiper-nav button.arrow-right{
  left:20px;
}
.detail-slider .swiper-nav button{
  background-color:#ffe603;
  border:none;
}
.detail-slider .swiper-nav button svg{
  fill:#000;
  margin-top:-4px;
}
.padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.projectDetails {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.projectDetails .section-details .inner h2,
.projectDetails .section-details .inner h3{
  font-size:20px !important;
  line-height: 27px !important;
}
/* .projectDetails .section-details .inner p{
  font-size:14px;
} */
.section-title h5 {
  font-size: 18px;
  color: #2f2f2f;
}
.section-details .custom-btn{
  padding:10px 20px;
  margin-top:10px;
  display:inline-block;
}

.amenities .section-details ul li{
  font-size:16px;
  line-height:24px;
  margin-bottom:10px;
  color:#16447d;
  list-style:disc;
}
.formcontainer{
  border-radius: 10px;
  background-color:#2f2f2f;
}
.formcontrole{
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  font-size: 16px;
    padding: 0 0 12px;
    height: auto;
    background: none;
    border: none;
    border-radius: 0;
    transition: all 300ms ease;
    margin: 5px 0;
    width:100%;
    display:block;
    margin-bottom:20px;
    border-bottom:1px solid #ddd;
}
.formcontrole:focus{
  outline:none;
}
textarea.formcontrole{
  resize: none;
}
.formcontainer .custom-btn{
  padding: 10px 20px;
  background-color:#ffe603;
  color:#000;
}
.formcontainer .contact-form .register, .formcontainer .contact-form .reg-inn {
  background:transparent;
}
.formcontainer .formgroup label{
  color:#fff;
}

.top-rated-project{
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 10%);
  border-radius: 10px;
  margin-top: 2rem;
}
.boxDetails a{
  text-decoration:none;
  color:#3b3b3b;
  font-size:16px;
  display:block;
  margin-bottom:5px;
  transition: all 300ms ease-in-out;
  font-weight:600;

}
.img-fluid img{
  width: 70px;
  height: 80px;
  object-fit: cover;
  margin-right:15px;
}
.boxDetails p{
  font-size:16px;
}
.boxDetails p span{
  color:#7ea700;
}
.projectDetails .section-details .accordion-item .accordion-button{
  padding:15px 0px;
  font-size:16px;
  font-weight:600;
  line-height:24px;
  background-color:transparent;
}
.projectDetails .section-details .accordion-item .accordion-button:focus{
  box-shadow:none;
  background-color:transparent;
}
.projectDetails .section-details .accordion-body{
  padding:15px 0px;
}
.related-propery .feature-image{
  height:180px;
}
.about-detail{
  position:sticky;
  top:120px;
}
.searchengen .search-box.border-none{
  border-right:none;
}
.flight-search{
  margin-top:70px;
  padding: 0px 16px;
}
.view-all{
  text-decoration:none;
  font-size:16px;
  color:#000;
}
.place-card {
  border-radius:15px;
}
.place-card .place-image{
  width:100%;
}
.recommanded-properties .place-card .place-image img{
  height:156px;
}
.recommanded-properties .place-card{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.recommanded-properties .place-card .place-area-content{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.recommanded-properties .swiper-slide{
  height:auto;
}
.place-card .place-image img{
  width:100%;
  height:100%;
  object-fit:cover;
  overflow:hidden;
  border-top-left-radius:15px;
  border-top-right-radius:15px;
}
.place-card .place-area-content{
  padding:10px;
  border-top:none;
  border:1px solid #ddd;
  border-bottom-left-radius:15px;
  border-bottom-right-radius:15px;
}
.place-card .place-area-content ul li:first-of-type{
  padding-right:30px;
  border-right:1px solid #ddd;
  margin-right:30px;
}
.place-card .place-area-content ul li{
  font-size:12px;
  font-weight:400;
  color:#707070;
}
.place-card .place-area-content p.mb-2{
  font-weight:300;
}
.place-card .place-area-content h4{
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-transform: uppercase;
}
.place-card .place-area-content h5, .read-more{
  font-size:16px;
  font-weight:500;
}
.place-buttons a{
  padding:7px 24px;
  border-radius:10px;
  text-align:center;
  font-size:14px;
  font-weight:600;
  background-color:#0b2230;
  color:#fff;
  text-decoration:none;
  display:inline-block;
}
.place-buttons a.enquiry-btn{
  background-color:#fff;
  color:#0b2230;
  border:1px solid #0b2230;
}
.place-buttons a.enquiry-btn svg{
  fill:#0b2230;
  font-size:15px;
}
.appart-title{
  position:absolute;
  right:10px;
  top:10px;
  padding:3px 10px;
  text-align:center;
  font-size:12px;
  color:#fff;
  border-radius:30px;
  display:inline-block;
  background-color:#0b2230;
  font-weight:400;
}
.quotes-left svg, .quotes-right svg{
  font-size:40px;
}
.test-cont-slide p{
  padding:10px 40px;
  font-size:16px;
  line-height:20px;
  font-weight:400;
  color:#000;
}
.owener{
  display:block;
  text-align:right;
  font-size:16px;
  font-weight:400;
}
.read-more{
  text-align:right;
  color:#000;
  text-decoration:none;
  display:inline-block;
  margin-left:auto;
}
.follow{
  position:absolute;
  top:15%;
  right:0;
  padding:20px 12px;
  border-top-left-radius:30px;
  border-bottom-left-radius:30px;
  background-color:#0b2230;
  z-index: 999;
}
.follow.fixed{
  position: fixed;
  top: 90px;
  right: 0;
  transform:inherit;
}
.follow h6{
  font-size:16px;
  color:#fff;
  text-align:center;
  font-weight:400;
}
.follow .footer-social-icon ul li{
  margin-right:0px;
  margin-bottom:15px;
  display:flex;
  justify-content:center;
}

.follow .footer-social-icon ul li a{
  width:30px;
  height:30px;
}
.follow .footer-social-icon{
  padding-bottom:10px;
  border-bottom:1px solid #ddd;
  margin-bottom:15px;
}
.follow .footer-social-icon.call{
  margin:0;
  padding:0;
  border:none;
}

/* listing page css */

.select-drop .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.select-drop .form-group label {
  position: relative;
  cursor: pointer;
  font-weight:500;
  font-size:16px;
}

.select-drop .form-group label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0b2230;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
}

.select-drop .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid #0b2230;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.price-area .inn-item:not(:last-of-type){
  border-right:1px solid #000;
  padding-right:30px;
  margin-right:30px;
}
.inn-item span{
  display:block;
  margin-bottom:0px;
  color:#0B2230;
  font-size:16px;
  font-weight:400;
}
.inn-item h4{
  font-size:28px;
  font-weight:700;

}

.list-card-content .desc p{
  font-size:16px;
  font-weight:400;
  line-height:20px;
  color:#0B2230; 
  display: none;
}
.list-card-content .desc p:nth-child(-n+3) {
  display: block; 
}

.list-card-content .desc a{
  float:right;
  margin-top:0px;
  display:inline-block;
  border:none;
  background:transparent;
  font-size:16px;
  font-weight:400;
  border-bottom:1px solid #0B2230;
  color:#0B2230;
  padding:0px 0px 0px;
  text-decoration:none;
}
.builder a{
  padding:6px 25px 8px 25px;
  border-radius:30px;
  color:#fff;
  text-align:center;
  font-size:16px;
  text-decoration:none;
  background-color:#0B2230;
  font-weight:500;
}
.builder-about span{
  font-size:16px;
  font-weight:400;
}
.builder-about h5{
  font-size:16px;
  font-weight:600;
  line-height:24px;
  color:#0B2230;
}
.builder a svg{
  fill:#fff;
  font-size:16px;
  margin-right:4px;
}
.product-inner-list-card{
  border-radius:20px;
  background-color:#e1dacd24;
}
.total-list-result{
  font-size:24px;
  font-weight:400;
  color:#000;
  padding-left:0px;
  margin-top:20px;
  display:block;
}

footer{
  background-image:url(../images/footer-bg.png);
  background-position: center;
  background-repeat: none;
  background-size:cover;
}
.copy-right{
  font-size:16px;
  font-weight:100;
  color:#FAFAF9;
}
.register, .mapPopup{
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.4);
  z-index:999;
  display:none;
}


.mapwrapper{
  background-color:#fff;
  padding:36px;
  width:600px;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  z-index:9
}
.mapwrapper .mapdata{
  width:100%;
  height:400px;
}
.register.active{
  display:block;
}
.mapPopup.activemap{
  display:block;
}
.register .reg-inn{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  width:500px;
  padding:15px 20px;
  background-color:#fff;
  overflow-y: scroll;
  height: 62vh;
  border-radius: 10px;
}
.register .reg-inn h1{
  font-size:22px;
  text-align:center;
  font-weight:600;
}
.formgroup{
  margin-bottom:10px;
}
.formgroup label{
  display:block;
  font-size:16px;
  color:#000;
  margin-bottom:5px;
  font-weight:600;
}
.formgroup input, .formgroup select, .formgroup textarea{
  width:100%;
  border:1px solid #ddd;
  padding:6px 10px;
  border-radius:5px;
  color:#000;
  font-size:16px;
  background-color:#fff;
}

.register .submit-btn button{
  position:static;
  transform:inherit;
}
.red{
  color:red;
  font-size:14px;
  margin-top:5px;
  display:block;
}
.close-btn{
  position:absolute;
  right:10px;
  top:4px;
  width:25px;
  height:25px;
  border:none;
  background-color:transparent;
  padding:0px;
}
.close-btn svg{
  font-size:30px;
}

.contact-form .register{
  position:static;
  display:block;
  transform:inherit;
  background:#fff;
  width:100%;
}
.contact-form .register .reg-inn{
  position:static;
  transform:inherit;
  height:auto;
  overflow-y: inherit;
  width:100%;
}
.contact-form  .formgroup input{
  padding:15px 10px;
}
.loader{
  display: flex;
  justify-content: center;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:9999;
  background-color:rgba(0, 0, 0, 0.5)
}
[class*=loader-] {
  display: inline-block;
  width: 4em;
  height: 4em;
  color: #0b2230;
  vertical-align: middle;
  pointer-events: none;
}
.loader-01{
    border: 3px solid transparent;
    border-top-color: #0477bf;
    border-radius: 50%;
    -webkit-animation: 1.5s loader-01 linear infinite;
    animation: 1.5s loader-01 linear infinite;
    position: relative;
}
.loader-01:before{
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 3px solid currentcolor;
    border-radius: 50%;
    opacity: 0.5
}

@keyframes loader-01{
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}


/*======================
    404 page
=======================*/


.page_404{ 
  padding:40px 0; background:#fff;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
    background-image: url('https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif');
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
  font-size:80px;
 }
 
.four_zero_four_bg h3{
    font-size:80px;
}
			 
  .link_404{			 
    color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration:none;
  }
  .contant_box_404{
     margin-top:-50px;
  }

  .nav-btn{
    opacity:0;
    visibility:hidden;
    display:none;
    padding:5px;
    border:none;
    cursor:pointer;
    transition:0.5s ease-in-out;
    background-color:transparent;
  }
  .nav-btn svg{
    fill: #fff;
    font-size: 15px;
    stroke: #fff;
    font-size: 32px;
  }

  .img-item img{
    width:100%;
    height:400px;
    object-fit:cover;
    border-radius:10px;
  }

  /* Loader.css */
.site-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.site-loader .spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.text-left{
  text-align: left;
}
.welc-txt{
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color:#0b2230;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom:20px;
}
.right-col img{
  width:100%;
  height:500px;
  border-radius: 10px;
}

.about-content .content{
  margin: 0 auto;
  position: relative;
  /* top: -100px; */
  background: #fff;
  padding: 50px;
  /* box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.2); */
}
.about-content .content p{
  color:#0000009c;
}

.img-item iframe{
  height:400px;
}
.contact_info a{
  color:#fff;
  margin:10px 0px;
}
.contact_info a:hover{
  color:#3498db;
}
.contact_info strong{
  font-weight:700;
  color:#fff;
}
.contact_info p{
  color:#fff;
}
.gallery-card img{
  width:100%;
}
.fixedMobileCall{
  display: none;
}
.floatvk {
  align-items: center;
  background-color: #25d366;
  border-radius: 50%;
  bottom: 45px;
  box-shadow: 0 2px 5px #0000004d;
  display: flex;
  height: 60px;
  justify-content: center;
  position: fixed;
  right: 20px;
  text-decoration: none;
  width: 60px;
}

.customtext {
display: block;
margin-top: -7px;
color: #0B2230;
font-size: 14px;
font-weight: 400;
margin-bottom: 10px;
}


.right_conatct_social_icon{
  background: linear-gradient(to top right, #0b2230 -5%, #25d36600 100%);
}
.contact_us{
 background-color: #f1f1f1;
 padding: 70px 0px;
}

.contact_inner{
 background-color: #fff;
 position: relative;
 box-shadow: 20px 22px 44px #cccc;
 border-radius: 0px;
}
.contact_field{
 padding: 60px 340px 90px 100px;
}
.right_conatct_social_icon{
 height: 100%;
}
.contact_field .formgroup input{
  padding:15px 10px;
}
.contact_field .formgroup input:focus{
  outline: none;
}

.contact_field h3{
color: #000;
 font-size: 40px;
 letter-spacing: 1px;
 font-weight: 600;
 margin-bottom: 10px
}
.contact_field p{
 color: #000;
 font-size: 13px;
 font-weight: 400;
 letter-spacing: 1px;
 margin-bottom: 35px;
}
.contact_field .form-control{
 border-radius: 0px;
 border: none;
 border-bottom: 1px solid #ccc;
}
.contact_field .form-control:focus{
 box-shadow: none;
 outline: none;
 border-bottom: 2px solid #1325e8;
}
.contact_field .form-control::placeholder{
 font-size: 13px;
 letter-spacing: 1px;
}

.contact_info_sec {
 position: absolute;
 background-color: #2d2d2d;
 right: 1px;
 top: 18%;
 height: 340px;
 width: 400px;
 padding: 40px;
 border-radius: 25px 0 0 25px;
 color:#fff;
}
.contact_info_sec h4{
 letter-spacing: 1px;
 padding-bottom: 15px;
}

.info_single{
 margin: 30px 0px;
}
.info_single i{
 margin-right: 15px;
}
.info_single span{
 font-size: 14px;
 letter-spacing: 1px;
}

button.contact_form_submit {
 background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
 border: none;
 color: #fff;
 padding: 10px 15px;
 width: 100%;
 margin-top: 25px;
 border-radius: 35px;
 cursor: pointer;
 font-size: 14px;
 letter-spacing: 2px;
}
.socil_item_inner li{
 list-style: none;
}
.socil_item_inner li a{
 color: #fff;
 margin: 0px 15px;
 font-size: 14px;
}
.socil_item_inner{
 padding-bottom: 10px;
}

.map_sec{
 padding: 50px 0px;
}
.map_inner h4, .map_inner p{
 color: #000;
 text-align: center
}
.map_inner p{
 font-size: 13px;
}
.map_bind{
margin-top: 50px;
 border-radius: 30px;
 overflow: hidden;
}
.privacy-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.privacy-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.privacy-content ul{
  list-style: disc;
}
.privacy-content ul li{
  font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #000;
}
.founder{
  font-size:15px;
  font-weight: 500;
  display: block;
  text-align: center;
}




/* my css */

  /* media query for responsive */
  @media only screen and (max-width:1399px){
    .inn-item h4{
      font-size:20px;
    }
    nav > ul > li:not(:last-of-type){
      margin-right: 15px;
    }
    .contact_field{
      padding: 60px 286px 90px 30px;
    }
  }

  @media only screen and (max-width:1199px){
    .logo a img{
      width:67px;
    }
    .contact_info_sec{
      padding:40px 15px 20px 20px;
      width:330px;
    }
    .contact_field {
      padding: 60px 230px 90px 30px;
  }
    nav ul li a{
      font-size:15px;
      padding: 5px 10px;
    }
    nav > ul > li:not(:last-of-type){
      margin-right: 15px;
    }
    .banner .banner-content h1 {
      font-size: 30px;
      line-height: 36px;
    }
    .banner .banner-content p {
      font-size: 18px;
      line-height: 24px;
    }
    .search-box{
      width:26%;
    }
    .search_drop input, .form-item label{
      font-size:16px;
    }
    .search_drop svg{
      right: 13px;
    }
    .search_drop input::placeholder {
      font-size:16px;
    }
    
    .search_drop input::-ms-input-placeholder { /* Edge 12 -18 */
      font-size:16px;
    }
    .submit-btn button{
      width:150px;
    }
    header{
      padding: 15px 0px;
    }
    header.fixed nav ul li a {
      padding: 5px 10px;
    }
    .banner_bg{
      height: 500px;
    }
    h2{
      font-size: 24px !important;
    }
    .card-image{
      height:250px;
    }
    .card-image .card-title{
      padding: 10px 10px;
      font-size: 18px;
    }
    .testimonial-slider h2{
      padding: 0px 20px;
      font-size: 24px !important;
    }
    .testcard{
      padding: 10px 25px 50px;
    }
    .testcard p, .place-card .place-area-content h4{
      font-size: 16px;
      line-height:27px;
    }
    /* .place-card .place-image{
      height: 170px;
    } */
    .place-card .place-area-content ul li:first-of-type{
      padding-right: 10px;
      margin-right: 10px;
    }
    .place-buttons a {
      padding: 7px 24px;
    }
    .test-cont-slide p{
      font-size: 18px;
      line-height: 27px;
    }
    .owener{
      font-size:20px;
    }
    .footer-menu ul li a{
      font-size: 16px;
      margin: 10px 0px;
    }
    .place-card .place-area-content h5{
      font-size:16px;
      line-height:27px;
    }
    .footer-menu h6{
      font-size:18px;
    }
    .list-card-content{
      width: calc(100% - 230px);
    }
    .price-area .inn-item:not(:last-of-type){
      width: 33%;
    }
    .inn-item h4{
      font-size:20px;
    }
    .product-inner-list-card .card-image{
      width: 230px;
      height: 200px;
    }
    .category-slider{
      padding:0px 30px;
    }
    .swiper-nav button.arrow-left{
      right:-15px;
    }
   
  }
  @media only screen and (max-width:991px){
    nav > ul > li:not(:last-of-type){
      margin-right:0px;
    }
    .contact_info_sec{
      position: static;
      width:100%;
      border-radius: 0px;
    }
    .contact_field{
      padding:30px;
    }
    .search-box{
      width:24%;
    }
    .searchengen{
      padding: 12px 15px;
    }
    nav ul li a{
      font-size:14px;
      padding:0px 10px;
    }
    .logo{
      padding-left:0px;
    }
    header.fixed{
      padding: 15px 0px;
    }
    header.fixed nav ul li a{
      padding:0px 10px;
    }
    .banner_bg{
      padding-top:75px;
      height: 440px;
    }
    .swiper-nav button.arrow-left{
      right:-25px;
    }
    
    .footer-left{
      margin-bottom:30px;
    }
    
    .list-card-content h3 a{
      font-size: 21px;
      line-height: 30px;
    }
    .list-card-content .location-title{
      font-size:16px;
    }
    
    .sidebar .search-box{
      margin-bottom:15px;
      padding-left:5px;
    }
    .listing-banner{
      height:300px;
    }
    .listing-banner .content-banner{
      margin-top:10px;
    }
    .banner.inner_banner{
      height:250px;
    }
    .banner .inner_banner .content-banner h1{
      margin-bottom:0px;
    }
    .img-item img{
      height:350px;
      margin-bottom:20px;
    }
    .mt-100{
      margin-top:20px;
    }
    .detail-heading h1{
      font-size:30px;
    }
    .detail-heading.mt-5{
      margin-top:20px !important;
    }
    .breadcrum{
      padding:0px;
    }
    .detail-slider{
      height:300px;
    }
    .detail-slider .slider-image img{
      object-fit:cover;
    }
    .slider-image {
      width: 100%;
      height: 100%;
    }
    .test-cont-slide p {
      font-size: 16px;
      line-height: 20px;

    }
    .owener {
      font-size: 16px;
  }
  .footer-menu{
    margin-top:30px;
  }
  .search_drop svg{
    right:0;
  }
    
  }
  @media only screen and (max-width:767px){
    .search_drop svg{
      top:15px;
    }
    .swiper-nav{
      top:100%;
      margin-top:0px;
    }
    .swiper-nav button.arrow-left{
      right:35%;
    }
    .swiper-nav button.arrow-right{
      left:35%;
    }
    .desc.test-cont-slide{
      padding-bottom:50px;
    }
    .banner .banner-content h1 {
      font-size: 20px;
      line-height: 27px;
    }
    .banner .banner-content p {
      font-size: 14px;
      line-height: 20px;
    }
    .banner .banner-content{
      margin-top: 0px;
    }
    .banner{
      height:auto;
      padding-bottom: 30px;
    }
    .banner_bg{
      height:532px;
    }
    .searchengen{
      border-radius: 10px;
      background-color: #ffffff59;
    }
    .searchengen .d-flex{
      flex-direction:column;
    }
    .banner:before{
      display: none;
    }
    .search-box{
      width:100%;
      border:1px solid #ddd !important;
      margin-bottom:10px;
      border-radius:10px;
      padding:10px 10px 0px;
    }
    .search-box:not(:last-of-type){
      border-right:none;
    }
    .search-box .form-item{
      background:transparent;
    }
    .submit-btn button{
      position:static;
      transform:inherit;
    }
    .flight-search{
      margin-top:20px;
      padding:0px;
    }
    .form-item label{
      font-weight:700;
      font-size:14px;
    }
    .submit-btn button{
      width:auto;
      height:auto;
      padding:10px 20px;
      font-size:18px;
    }
    .nav-btn{
      opacity:1;
      visibility:visible;
      display:block;
    }
    header nav{
      position:fixed;
      top:0;
      right:0;
      width:300px;
      height:100%;
      background-color:#0b2230;
      transition:0.5s ease-in-out;
      transform:translateX(100%);
      padding-top:60px;
    }
    header .responsive_nav{
      transform:none;
    }
    header nav ul{
      flex-direction:column;
    }
    nav ul li a{
      font-size:14px;
      padding:10px 20px;
      display:block;
    }
    header.fixed nav ul li a{
      padding:10px 20px;
    }
    nav ul li a svg{
      right:15px;
      position:absolute;
      font-size:25px;
    }
    .category-slider{
      padding:0px 0px;
    }
    .nav-btn.nav-close-btn{
      position: absolute;
      right: 10px;
      top: 10px
    }
    nav ul li{
      text-align: left;
      width: 100%;
    }
    nav ul li:not(:last-of-type){
      border-bottom: 1px solid #dddddd26;
      margin-right:0px;
    }
    nav ul li.active .dropdownMenu{
      display:block;
    }
    nav ul li .dropdownMenu{
      position:static;
      transform:inherit;
      opacity:1;
      visibility:visible;
      display:none;
      width:100%;
    }
    .banner_bg{
      padding-top:90px;
    }
   
    .logo a img{
      width:50px;
    }
    header.fixed {
      padding: 5px 0px;
    }
    footer{
      padding-bottom:50px;
    }
    .footer-menu ul li a{
      margin:0px;
    }
    .footer-menu h6{
      margin-bottom:10px;
    }
    .footer-menu{
      margin-top:30px;
    }
    .footer-left{
      margin-bottom:0px;
    }
    .footer-left img{
      width:60px;
    }
    .footer-wrapper.pt-5{
      padding-top:20px !important;
    }
    .test-cont-slide p{
      padding:0px 10px;
    }
    .place-buttons{
      margin-bottom:20px;
    }
    h2 {
      font-size: 20px !important;
    }
    .testcard{
      padding:0px;
    }
    .testimonial.mt-5{
      margin-top:10px !important;
    }
    .listing-banner{
      height: 78px !important;
    }
    .listing-banner h1{
      font-size:20px;
    }
    .product-inner-list-card{
      flex-wrap:wrap;
    }
    .product-inner-list-card .card-image{
      width:100%;
      height:200px;
      margin-bottom:20px;
    }
    .list-card-content{
      width:100%;
      padding-left:0px;
    }
    .builder{
      width:100%;
    }
    .inn-item h4{
      font-size:13px;
    }
    .list-card-content h3 a{
      font-size:18px;
      line-height: 24px;
    }
    .builder-about h5{
      font-size:16px;
    }
    .list-product.my-5{
      margin-top:20px !important;
    }
    .row-reverse{
      flex-direction:column-reverse;
    }
    .img-item iframe{
      height:250px;
    }
    .follow{
      padding: 10px 2px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      display: none;
    }
    .follow h6 {
      font-size: 9px;
    }
    .follow .footer-social-icon ul li a {
      width: 20px;
      height: 20px;
    }
    .follow .footer-social-icon ul li a svg {
      font-size: 10px;
    }
    .follow .footer-social-icon ul li{
      margin-bottom:10px;
    }
    .follow .footer-social-icon {
      padding-bottom: 0;
      margin-bottom: 10px;
    }
    .detail-heading h1{
      font-size:24px;
      line-height:27px;
    }
    .register .reg-inn{
      width:95%;
      height:75vh;
    }
    .sidebar form{
      flex-direction:column;
    }
    .sidebar .search-box{
      width:100%;
      border:none !important;
      margin-bottom:0px;
    }
    .list-card-content .desc p{
      font-size:14px;
      line-height:20px;
    }
    .price-area .inn-item:not(:last-of-type){
      width:35%;
      padding-right: 12px;
    margin-right: 10px;
    }
  
    .search-box .submit-btn{
      display:flex;
      justify-content:center;
    }
    .submit-btn button svg{
      margin-right:0;
    }
    .gallery-card img{
      height: auto;
    }
    .fixedMobileCall{
      position: fixed;
      left:0;
      bottom:0;
      z-index: 999;
      width:100%;
      display: block;
    }
    .fixedMobileCall a{
      color: #fff;
      padding: 7px 20px;
      background-color: #0b2230;
      text-decoration: none;
      text-align: center;
      display: block;
      font-size: 20px;
      width:100%;
    }
    .fixedMobileCall a svg{
      margin-right:10px;
    }
    p, .about-content .content ul li{
      font-size:14px;
      line-height: 22px;
    }
    .about-content .content h3{
      font-size:20px;
    }
    .about-content .content{
      padding:20px;
    }
  }
 

  
